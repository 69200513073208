<template>
    <v-container class="mt-4 px-5" fluid>
        <v-card class="shadowCard">
            <v-card-title class="text-subtitle-1">
      	        Rol de clases
                <v-spacer></v-spacer>
                <v-col cols="12" sm="2" class="d-flex justify-end pa-0">
                    <v-btn justify="end" @click="consultar()" color="primary">Actualizar</v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-autocomplete filled dense clearable v-model="ciclo" :items="ciclos" label="Selecciona ciclo" persistent-hint
                            single-line hide-details return-object item-text="ciclo" item-value="id_ciclo">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete filled dense clearable v-model="dia" :items="dias" label="Selecciona dia" persistent-hint
                            single-line hide-details return-object item-text="dia" item-value="id_dia">
                        </v-autocomplete>
                    </v-col>
                    
                </v-row>
                <br>
                <v-row>
                    <v-col>
                        <p>
                            Teachers necesarios para cubrir el día: {{ totalTeachers }}
                            <v-divider></v-divider>
                            Teachers mujeres para cubrir grupos teens/kids: {{ totalTeachersMujeres }}
                            <br>
                            Teachers genero indistinto: {{ totalTeachers - totalTeachersMujeres }}
                            
                        </p>
                    </v-col>
                    <v-col>
                        Cantidad mas alta de grupos por teacher: {{ MaxGruposXteacher }}
                    </v-col>
                </v-row>
                
                <v-simple-table fixed-header height="60vh">
                    <thead>
                        <tr>
                            <!-- <th class="text-left" style="width: 25px;">
                                Plantel
                            </th> -->
                            <th class="text-left">
                                Grupos
                            </th>
                            <th class="text-left" v-for="(horario, i) in HorasConActividad" :key="i">
                                Horario: {{ horario }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(grupo, i) in grupos" :key="i">
                            <!-- <td v-for="(plantel, j) in planteles" v-if="plantel.id_plantel == grupo.id_plantel" :key="j" style="width: 25px;" :rowspan="plantel.totalgrupos">{{plantel.plantel}}</td> -->
                            <td>{{ grupo.nombre_grupo }}</td>
                            <td v-for="(horario, i) in HorasConActividad" :key="i" :class="itemBackground(horario, grupo)">
                                <!-- <v-chip v-if="horario == grupo.hora_inicio || horario == grupo.hora_fin || (horario > grupo.hora_inicio && horario < grupo.hora_fin)" 
                                color="green" dark>
                                    <v-icon>mdi-check</v-icon>
                                </v-chip> -->
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>

                <template>
                    <v-data-table
                        :headers="teachersHeaders"
                        :items="teachersPerfectos"
                        item-key="idTeacher"
                        show-expand
                        class="elevation-1"
                    >
                        <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Lista de Teachers Generados</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        </template>

                        <template v-slot:item.sexo="{item}">
                            <p v-if="item.sexo == 'M'">Femenino</p>
                            <p v-else>Indistinto</p>
                        </template>

                        <template v-slot:item.id_plantel="{item}">
                            <p v-if="[16,17].includes(item.id_plantel)">INBI Online / FAST Online</p>
                            <p v-for="(plantel, i) in planteles" :key="i" v-if="plantel.id_plantel == item.id_plantel && ![16,17].includes(item.id_plantel)">{{ plantel.plantel }}</p>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <tr v-for="(grupo, i) in item.grupos" :key="i">
                                {{ grupo.nombre_grupo }}
                            </tr>
                        </td>
                        </template>
                    </v-data-table>
                </template>
                <!-- <template>
                    <v-data-table
                        :headers="teachersHeaders2"
                        :items="teachers"
                        item-key="id_usuario"
                        show-expand
                        class="elevation-1"
                    >
                        <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Teachers Reales</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        </template>

                        <template v-slot:item.sexo="{item}">
                            <p v-if="item.sexo == 'M'">Femenino</p>
                            <p v-else>Masculino</p>
                        </template>

                        <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-5">
                            <tr v-for="(grupo, i) in item.grupos" :key="i">
                                {{ grupo.nombre_grupo }}
                            </tr>
                        </td>
                        </template>
                    </v-data-table>
                </template> -->
            </v-card-text>
        </v-card>

        <!-- Dialog de carga -->
        <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
        <carga                v-if="cargar"/>
    </v-container>
</template>

<script>
    import axios from 'axios';
	import { mapGetters } from 'vuex'

    import Alerta                from '@/components/alertas/Alerta.vue'
    import carga                 from '@/components/alertas/carga.vue';

    import validarErrores        from '@/mixins/validarErrores'

    var moment = require('moment');
    moment.locale(); 

    export default {
        components:{
            Alerta,
            carga,
        },

        mixins: [ validarErrores ],

        data: () => ({
            cargar:false,
            ciclos:[],
            ciclo:null,
            totalTeachers : 0,
            MaxGruposXteacher : 0,
            totalTeachersMujeres : 0,
            planteles: [],
            teachers: [],
            teachersPerfectos: [],
            HorasConActividad: [],
            grupos: [],
            dias:[
                {dia:"Lunes", id_dia: 1},
                {dia:"Martes", id_dia: 2},
                {dia:"Miércoles ", id_dia: 3},
                {dia:"Jueves", id_dia: 4},
                {dia:"Viernes", id_dia: 5},
                {dia:"Sábado", id_dia: 6},
                {dia:"Domingo", id_dia: 7},
            ],
            teachersHeaders:[
            { text: 'Teacher'                 , value: 'idTeacher'            },
            { text: 'Plantel'                 , value: 'id_plantel'           },
            { text: 'Sexo'                    , value: 'sexo'                 },
            { text: 'TotalGrupos'             , value: 'totalgrupos'          },
            ],
            teachersHeaders2:[
            { text: 'ID'                      , value: 'id_usuario'           },
            { text: 'Teacher'                 , value: 'nombre_completo'      },
            { text: 'Plantel'                 , value: 'plantelesTexto'       },
            { text: 'Sexo'                    , value: 'sexo'                 },
            { text: 'TotalGrupos'             , value: 'totalgrupos'          },
            ],
            dia: 0,
            payload: []
    	}),

        computed:{
        ...mapGetters('login',['getdatosUsuario']),
        },

        watch: {
            ciclo () {
                if(this.ciclo){
                    this.consultar()
                }
            },

            dia () {
                if(this.dia){
                    this.consultar()
                }
            },
        },
        async created () {
            await this.initialize()
        },

        methods: {
            initialize(){
                this.getCiclos()
            },

            itemBackground(horario, grupo) {
                if(horario == grupo.hora_inicio){
                    return 'fondoverde1'
                } 
                if(horario == grupo.hora_fin){
                    return 'fondoverde2'
                }
                if(horario > grupo.hora_inicio && horario < grupo.hora_fin)
                    return 'fondoverde'
                
                //return horario == grupo.hora_inicio || horario == grupo.hora_fin || (horario > grupo.hora_inicio && horario < grupo.hora_fin) ? 'fondoverde' : ''
            },

            getCiclos(){
                this.$http.get('roles.ciclos.all').then(response=>{
                    // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
                    // y solo queremos los ciclos que en su nombre dicen ciclo 
                    for(const i in response.data){
                        //  si es diferente a FE
                        if (!(response.data[i].ciclo.search('FE') != -1)) {
                        // Y es igual a FE, se agrega a ciclos
                        // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
                        if(response.data[i].ciclo.search('CICLO') != -1){
                            this.ciclos.push(response.data[i])
                        }
                        }
                    }

                    }).catch( error =>{
                    this.validarError( error.response.data.message )
                    }).finally( () => { this.cargar = false })
            },
            plantelesIDtoText(){
                try{
                    this.teachers.forEach(a=>{
                        a.plantelesTexto = '';
                        a.planteles.forEach(b=>{
                            let nombreplantel = this.planteles.find(c=> c.id_plantel == b.idplantel)
                            if(a.plantelesTexto == ''){
                                a.plantelesTexto = nombreplantel.plantel
                            }else{
                                a.plantelesTexto = a.plantelesTexto + ', ' + nombreplantel.plantel
                            }
                        })
                    })
                    console.log(this.teachers)
                } catch (err){
                    console.log(err)
                }
            },

            consultar(){
                if( !this.ciclo || !this.dia ){
                    return true
                }
                this.HorasConActividad = []
                this.MaxGruposXteacher = 0
                this.totalTeachers = 0
                this.teachers = []
                this.totalTeachersMujeres = 0
                this.grupos     = []
                this.teachersPerfectos     = []
                this.planteles     = []
                this.cargar     = true
                this.payload    = {
                    id_ciclo: this.ciclo.id_ciclo,                          //id ciclo inbi
                    id_ciclo_relacionado: this.ciclo.id_ciclo_relacionado,  //id ciclo fast
                    dia: this.dia.id_dia                                    //dia a consultar
                }
                console.log(this.payload)
                this.$http.post('rol.clases2', this.payload ).then(response=>{
                    //obtenemos horarios y grupos
                    this.HorasConActividad = response.data.HorasConActividad
                    this.grupos = response.data.grupos
                    this.planteles = response.data.planteles
                    this.totalTeachers = response.data.teachersNecesarios
                    this.totalTeachersMujeres = response.data.teachersMujeres
                    this.teachers = response.data.teachers
                    this.teachersPerfectos = response.data.teachersPerfectos
                    this.MaxGruposXteacher = response.data.CantidadMasAltaGruposxTeacher 
                    this.plantelesIDtoText()
                    console.log(this.teachersPerfectos)
                }).catch( error =>{
                    this.validarError( error.response.data.message )
                }).finally( () => { this.cargar = false })
            }
        }
    }

    
</script>


<style>
    .fondoverde1{
        border-radius: 15px 0 0 15px;
        background-color: rgb(54, 165, 54);
    }
    .fondoverde2{
        border-radius: 0 15px 15px 0;
        background-color: rgb(54, 165, 54);
    }
    .fondoverde{
        background-color: rgb(54, 165, 54);
    }

    .v-data-table__wrapper{

    }

    .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
    }

    .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
    }

    .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
    }

    .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
    }

    #yellow{
    background-color: yellow !important;
    }

</style>